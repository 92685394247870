<template>
  <g :fill="color" fill-rule="nonzero">
    <path
      d="M4.25 5.002v-.01.005c0-.998 1.495-1 1.5-.005.021.69.338 1.254 1.25 1.254.938 0 1.246-.601 1.25-1.237v-.046c.017-.46.36-.7.713-.715a.746.746 0 0 1 .088 0c.35.022.686.262.699.722v.04c.005.635.313 1.236 1.25 1.236.58 0 .89-.206 1.08-.585.105-.211.154-.434.166-.586l-.831-3.325h-8.83l-.831 3.325c.012.152.06.375.167.586.19.38.498.585 1.079.585.882 0 1.205-.521 1.249-1.223v-.021zM7 7.746c-.86 0-1.531-.295-2-.802-.47.508-1.143.802-2 .802-1.17 0-1.985-.543-2.42-1.414a3.037 3.037 0 0 1-.33-1.335l.022-.182 1-3.997A.75.75 0 0 1 2 .25h10a.75.75 0 0 1 .728.568l1 3.997.022.182c0 .356-.08.838-.33 1.335-.435.87-1.25 1.414-2.42 1.414-.863 0-1.533-.296-2-.8-.467.504-1.137.8-2 .8zM11.25 9.994v3.998c0 .138-.111.249-.25.249H3a.249.249 0 0 1-.25-.25V9.995a.75.75 0 1 0-1.5 0v3.998c0 .966.783 1.749 1.75 1.749h8c.967 0 1.75-.783 1.75-1.75V9.995a.75.75 0 1 0-1.5 0z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
